/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { uniqueId } from 'lodash';
import { Translator } from './translator';
import * as globals from '../../globals';
import { decodeEventKeys, encodeEventKeys } from '../../constants/asset/event';
import AnyObject = globals.AnyObject;

const keyConfig = {
    main: {
        encode: encodeEventKeys,
        decode: decodeEventKeys,
    },
};

class Event extends Translator {
    decode = (record: AnyObject) => {
        const { decode: decodeKeys } = this.keys.main;
        return this.translate(decodeKeys, record, { _key: uniqueId('event-') });
    };

    encode = (record: AnyObject) => {
        const { encode: encodeKeys } = this.keys.main;
        return this.translate(encodeKeys, record);
    };
}

export default new Event(keyConfig);
