/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */
import { get } from 'lodash';
import { currentUserDefaults } from '../shared/constants/auth';
import Auth, { AuthTargetedTypes } from '../shared/services/auth';
import { asyncDefaults, makeAsyncReducer, mergeReducers } from '../shared/utils/async';
import {
    LOGIN,
    LOGOUT,
    RIDB_ACTION_PREFIX,
    RELOAD,
    UPDATE_CURRENT_USER,
} from '../shared/constants/actions';
import { keysToCamelCase } from '../shared/utils/object';

const login = makeAsyncReducer(LOGIN);
const logout = makeAsyncReducer(LOGOUT);
const reload = makeAsyncReducer(RELOAD);
const update = makeAsyncReducer(UPDATE_CURRENT_USER, undefined, {
    success: (state, action) => {
        // Ensure that any new user data is properly camelCased before applying it
        const userData = keysToCamelCase(action.payload);
        const apiKey = { ...state.data.apiKey, ...userData.apiKey };
        const data = { ...state.data, ...{ apiKey } };

        // Save the updated apiKey to the session or local store
        Auth.target(AuthTargetedTypes.USER, data);
        Auth.target(AuthTargetedTypes.API_KEY, apiKey.apiKey);

        return { loading: false, loaded: true, error: null, data };
    },
    start: undefined,
    failure: undefined,
});

export const getCurrentUser = (state) => {
    const { error, loaded, loading, data } = get(state, 'portal.currentUser');
    if (error || !loaded || loading) {
        return currentUserDefaults;
    }
    return data;
};

export default mergeReducers(
    asyncDefaults(currentUserDefaults),
    RIDB_ACTION_PREFIX,
    login,
    logout,
    reload,
    update
);
