/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { uniqueId } from 'lodash';
import * as globals from '../../globals';
import { EncodeDecodeKeys, IBaseKeyConfig, Translator } from './translator';
import { IDecodedAssetItem } from '../../types/asset/asset';
import AnyObject = globals.AnyObject;
import UniqueKey = globals.UniqueKey;
import UniqueKeyObject = globals.UniqueKeyObject;

export interface IItemKeyConfig extends IBaseKeyConfig {
    activity: EncodeDecodeKeys;
    address: EncodeDecodeKeys;
}

export abstract class ItemTranslator extends Translator<IItemKeyConfig> {
    abstract decodeConverse<T = any>(records: T[]): UniqueKeyObject<IDecodedAssetItem>[];

    encodeActivity = (record: AnyObject) => {
        const { encode: encodedKeys } = this.keys.activity;
        return this.translate(encodedKeys, record);
    };

    encodeAddress = (record: AnyObject) => {
        const { encode: encodedKeys } = this.keys.address;
        return this.translate(encodedKeys, record);
    };

    decodeActivities = (records: AnyObject[]): UniqueKeyObject[] => {
        const { decode: decodedKeys } = this.keys.activity;
        return records.map((record) => {
            const activity = { _key: uniqueId('activity-') };
            return this.translate<UniqueKey>(decodedKeys, record, activity);
        });
    };

    decodeAddresses = (records: AnyObject[]): UniqueKeyObject[] => {
        const { decode: decodedKeys } = this.keys.address;
        return records.map((record) => {
            const address = { _key: uniqueId('address-') };
            return this.translate<UniqueKey>(decodedKeys, record, address);
        });
    };
}
