/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { decrypt, encrypt } from '../utils/encryption';

export class LocalStorage {
    /**
     * Encrypt localStorage entry to prevent user manipulation
     * @param key
     * @param value
     * @param salt - defaults to process.env.ENCRYPTION_KEY
     */
    public static setItem(key: string, value: any, salt = process.env['ENCRYPTION_KEY']) {
        if (!(typeof value === 'string')) {
            value = JSON.stringify(value);
        }
        const encryptedKey = encrypt(salt, key);
        const encryptedValue = encrypt(salt, value);
        localStorage.setItem(encryptedKey, encryptedValue);
    }

    /**
     * Decrypt localStorage value and return it
     * @param key
     * @param salt - defaults to EncryptionService.KEY
     */
    public static getItem(key: string, salt = process.env['ENCRYPTION_KEY']): any {
        const encryptedKey = encrypt(salt, key);
        let finalValue = localStorage.getItem(encryptedKey);
        if (!finalValue) {
            return undefined;
        }
        finalValue = decrypt(salt, finalValue);
        let val = finalValue;
        try {
            val = JSON.parse(finalValue);
        } catch (e) {
            val = finalValue;
        }
        return val;
    }

    /**
     * Remove the item from local storage
     * @param key
     * @param salt - defaults to EncryptionService.KEY
     */
    public static removeItem(key: string, salt = process.env['ENCRYPTION_KEY']) {
        const encryptedKey = encrypt(salt, key);
        localStorage.removeItem(encryptedKey);
    }
}
