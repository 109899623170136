/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { Component, KeyboardEvent } from 'react';
import { debounce } from 'lodash';
import { Button, Icon, Select, SelectOption } from 'sarsaparilla';
import '../../scss/components/_StewardSearchForm.scss';
import IconTextInput from '../../shared/components/IconTextInput';
import * as globals from '../../shared/globals';
import { IOrganization } from '../../shared/types/asset/organization';
import { ISearchQuery } from '../../shared/types/search';
import { flattenOrg } from '../../shared/utils/organization';
import { toStringSafe } from '../../shared/utils/string';
import AnyFunction = globals.AnyFunction;
import TargetedEvent = globals.TargetedEvent;
import mergeDefaults = globals.mergeDefaults;

export const stewardSearchFormDefaultProps = {
    name: '',
    orgId: '',
    // @ts-expect-error
    orgOptions: [],
    terms: '',
    searchOnChange: false,
    showLabel: true,
    showOrgSelect: true,
    showLimitSelect: false,
};

type StewardSearchFormProps = {
    id: string;
    name?: string;
    orgId?: string | number;
    orgOptions?: IOrganization[];
    terms?: string;
    searchOnChange?: boolean;
    showLabel?: boolean;
    showOrgSelect?: boolean;
    showLimitSelect?: boolean;
    onSearch: AnyFunction;
    onInsert: AnyFunction;
};

type StewardSearchFormState = {
    orgId: Pick<StewardSearchFormProps, 'orgId'>;
    query?: Partial<ISearchQuery>;
};

export class StewardSearchForm extends Component<
    StewardSearchFormProps,
    StewardSearchFormState
> {
    static readonly itemsPerPage = [10, 20, 30, 40, 50];

    constructor(props: any) {
        super(props);

        const { showOrgSelect, orgOptions, orgId, id, terms } = props;
        let orgIds = [];

        if (showOrgSelect && orgOptions.length > 0) {
            const option = orgOptions.find((o: any) => o.value === orgId);
            if (option) {
                orgIds = flattenOrg(option.org).map(({ id: _id }: any) => _id);
            }
        }

        this.state = {
            orgId,
            query: { assetTypes: [id], terms, orgIds },
        };

        this.searchOnChange = debounce(this.searchOnChange, 350);
    }

    componentDidUpdate(prevProps: StewardSearchFormProps) {
        const { orgOptions } = this.props;
        const { orgId } = this.state;
        if (orgOptions !== prevProps.orgOptions) {
            // @ts-expect-error
            if (orgId === '' && orgOptions.length > 0) {
                this.setOrg(orgOptions[0]);
            }
        }
    }

    setTerms = (terms: string) => {
        this.setState(
            (prevState) => ({
                ...prevState,
                query: { ...prevState.query, terms },
            }),
            this.searchOnChange
        );
    };

    setOrgId = (event: TargetedEvent) => {
        const { value } = event.target;
        const { orgOptions } = this.props;
        const option = orgOptions.find((o) => o.value === value);
        if (!option?.org) {
            return;
        }
        const orgIds = flattenOrg(option.org).map(({ id: _id }: any) => _id);

        this.setState(
            (prevState) => ({
                ...prevState,
                orgId: option.value,
                query: { ...prevState.query, orgIds },
            }),
            this.searchOnChange
        );
    };

    setOrg = (option: IOrganization) => {
        const { org, value: orgId } = option;
        const orgIds = flattenOrg(org).map(({ id: _id }: any) => _id);

        this.setState((prevState) => ({
            ...prevState,
            orgId,
            query: { ...prevState.query, orgIds },
        }));
    };

    onKeyDownSearch = (ev: KeyboardEvent<any>) => {
        if (ev.key === 'Enter') {
            this.doSearch();
        }
    };

    setLimit = (event: TargetedEvent) => {
        const { value } = event.target;
        const limit = Math.min(50, Math.max(10, parseInt(value, 10)));

        this.setState(
            (prevState) => ({ ...prevState, query: { ...prevState.query, limit } }),
            this.searchOnChange
        );
    };

    searchOnChange = () => {
        const { searchOnChange } = this.props;
        if (searchOnChange) {
            this.doSearch();
        }
    };

    doSearch = () => this.props.onSearch(this.state.query);

    doInsert = () => this.props.onInsert(this.state.query);

    render() {
        const {
            name,
            id,
            orgOptions,
            searchOnChange,
            showLabel,
            showLimitSelect,
            showOrgSelect,
        } = mergeDefaults<StewardSearchFormProps>(
            this.props,
            stewardSearchFormDefaultProps
        );
        const {
            orgId,
            query: { terms, limit },
        } = this.state;

        return (
            <div className="row search-form">
                {showLabel && (
                    <div className="cell label label-width right-margin-25">{name}:</div>
                )}
                <div className="cell min-width">
                    <IconTextInput
                        id={`${id}-search-terms`}
                        value={terms}
                        placeholder="Search Text"
                        iconLeft={() => <Icon iconName="search" />}
                        onKeyDown={this.onKeyDownSearch}
                        onChange={this.setTerms}
                    />
                </div>
                {showOrgSelect && (
                    <div className="cell min-width">
                        <Select
                            id={`${id}-search-org`}
                            // @ts-expect-error
                            value={orgId}
                            label=""
                            isInvalid={!orgId}
                            onChange={this.setOrgId}
                        >
                            <SelectOption key="empty" value="">
                                Select a {name}
                            </SelectOption>
                            {orgOptions.map(({ label, value }) => (
                                // @ts-expect-error
                                <SelectOption key={value} value={value} label="">
                                    {label}
                                </SelectOption>
                            ))}
                        </Select>
                    </div>
                )}
                {!searchOnChange && (
                    <div className="cell">
                        <Button
                            shouldFitContainer
                            isDisabled={!orgId}
                            onClick={this.doSearch}
                        >
                            Search {name}
                        </Button>
                    </div>
                )}
                {showLimitSelect && (
                    <div className="align-right cell biggest align-baseline">
                        <Select
                            label="Records Per Page"
                            id="records-per-page"
                            name="records-per-page"
                            value={toStringSafe(limit)}
                            className="auto-width"
                            onChange={this.setLimit}
                        >
                            {StewardSearchForm.itemsPerPage.map((i) => (
                                // @ts-expect-error
                                <SelectOption key={i} value={toStringSafe(i)} label={i}>
                                    {i}
                                </SelectOption>
                            ))}
                        </Select>
                        &nbsp; records per page
                    </div>
                )}

                <div className="cell">
                    <Button shouldFitContainer onClick={this.doInsert}>
                        Insert {name}
                    </Button>
                </div>
            </div>
        );
    }
}
