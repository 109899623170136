/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { keysToSnakeCase } from '../shared/utils/object';
import { makeAsyncAction, makeAsyncResetAction } from '../shared/utils/async';
import {
    FETCH_ASSET,
    FETCH_ASSETS,
    FETCH_ASSETS_FOR_AUTOCOMPLETE,
    RESET_ASSET,
    RESET_ASSETS_FOR_AUTOCOMPLETE,
    SAVE_ASSET,
} from '../shared/constants/actions';
import { buildAssetAPICall, formatArrayQueryParams } from '../shared/utils/asset';
import { IAssetChange } from '../shared/types/asset/asset';
import Asset from '../shared/utils/translators/asset';
import API from '../shared/api';

export const fetchAssets = makeAsyncAction(FETCH_ASSETS, (query) => () => {
    const { assetTypes = [], orgIds = [] } = query;
    const toBeFormatted = { assetTypes, orgIds };
    return API.fetchAssets({
        ...keysToSnakeCase(query),
        ...formatArrayQueryParams(toBeFormatted),
    });
});

export const fetchAsset = makeAsyncAction(
    FETCH_ASSET,
    (type, id, query) => () => {
        const { assetTypes = [], orgIds = [] } = query;
        const toBeFormatted = { assetTypes, orgIds };
        return API.fetchAsset(type, id, {
            ...keysToSnakeCase(query),
            ...formatArrayQueryParams(toBeFormatted),
        });
    },
    undefined,
    {
        success: (dispatch, { type, payload }) =>
            dispatch({ type, payload: Asset.decode(payload) }),
    }
);

export const resetAsset = makeAsyncResetAction(RESET_ASSET);

export const saveAsset = makeAsyncAction(SAVE_ASSET, (asset, changes) => () => {
    // Used for any insert we might need to do
    let assetId = asset.id;

    // Turn the changes into { record: {}, verb: 'post|put|delete', path: '...' }
    changes.forEach(async (change: IAssetChange) => {
        const { changes: chngs, type } = change;
        if (!chngs || Object.keys(chngs).length === 0) {
            return;
        }

        const { url, data, options, verb } = buildAssetAPICall(change, assetId);
        let response = null;

        switch (verb) {
            case 'POST':
                response = await API.createResource(url, data, options);
                assetId =
                    type === 'facilities'
                        ? (response as any).FACILITYID
                        : (response as any).RECAREAID;
                break;
            case 'PUT':
                response = await API.updateResource(url, data, options);
                break;
            case 'DELETE':
                response = await API.deleteResource(url);
                break;
            default:
        }
    });

    return Promise.resolve({ data: true });
});

/**  @Migration (terms) => () => api.fetchAssets({ terms, assetTypes: ['recarea'] }) */
export const fetchAssetsForAutoComplete = makeAsyncAction(
    FETCH_ASSETS_FOR_AUTOCOMPLETE,
    (query) => () => API.fetchAssets(keysToSnakeCase(query))
);

export const resetAssetsForAutoComplete = makeAsyncResetAction(
    RESET_ASSETS_FOR_AUTOCOMPLETE
);
