/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Button, HelmetWrapper, SkipNavContent, TextField } from 'sarsaparilla';

import { getCurrentUser } from '../../reducers/currentUser';
import ClickWrapModal from './ClickWrapModal';
import { ICurrentUser } from '../../shared/types/auth/currentUser';
import * as globals from '../../shared/globals';
import { generateApiKey as _generateApiKey } from '../../actions/apiKey';
import { updateCurrentUser as _updateCurrentUser } from '../../actions/currentUser';
import { currentUserDefaults } from '../../shared/constants/auth';
import AnyFunction = globals.AnyFunction;
import mergeDefaults = globals.mergeDefaults;
import Auth from '../../shared/services/auth';
import '../../scss/containers/_ProfilePage.scss';
import RidbLink from '../../shared/components/RidbLink';

type ProfilePageProps = {
    currentUser?: ICurrentUser;
    generateApiKey: AnyFunction;
    updateCurrentUser: AnyFunction;
};

type ProfilePageState = {
    showAgreementModal: boolean;
};

export class ProfilePage extends Component<ProfilePageProps, ProfilePageState> {
    state = {
        showAgreementModal: false,
    };

    handleProfileUpdateError(error: Error) {
        // If in future client wants error handling like a popup, or message, do that here.
        console.error(error);
    }

    generateApiKey = () => {
        const { generateApiKey, updateCurrentUser } = this.props;
        generateApiKey()
            .then((apiKey: string) => updateCurrentUser({ apiKey }))
            .catch(this.handleProfileUpdateError);
    };

    goEditProfile = () => {
        window.location.href = `${process.env.SITE_ROOT}/account/profile`;
    };

    enableDeveloperAccess = () => this.setState({ showAgreementModal: true });

    onModalCancel = () => this.setState({ showAgreementModal: false });

    onModalContinue = () => {
        this.onModalCancel();
        this.props
            .updateCurrentUser({ isDeveloper: true })
            .then(this.generateApiKey)
            .catch(this.handleProfileUpdateError);
    };

    render() {
        const { showAgreementModal } = this.state;
        const { currentUser } = this.props;
        const {
            isDeveloper,
            loggedIn,
            apiKey: { apiKey },
            account: { firstName, lastName, email, homePhone },
        } = mergeDefaults<ICurrentUser>(currentUser, currentUserDefaults);
        if (!loggedIn && !Auth.isLoggedIn()) {
            return (
                <div>
                    Please <RidbLink to="/login">login</RidbLink> to view your profile
                </div>
            );
        }

        return (
            <SkipNavContent>
                <HelmetWrapper title="RIDB Profile" />
                <main role="main" className="profile-page">
                    <h1>Profile</h1>
                    <div className="modal-box">
                        <div className="row">
                            <div className="cell">
                                {firstName && (
                                    <TextField
                                        id="profile-fname"
                                        label="First Name"
                                        value={firstName}
                                        isDisabled
                                    />
                                )}
                            </div>
                            <div className="cell">
                                {lastName && (
                                    <TextField
                                        id="profile-lname"
                                        label="Last Name"
                                        value={lastName}
                                        isDisabled
                                    />
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="cell">
                                {email && (
                                    <TextField
                                        id="profile-email"
                                        label="Email"
                                        value={email}
                                        isDisabled
                                    />
                                )}
                            </div>
                            <div className="cell">
                                <TextField
                                    id="profile-phone"
                                    label="Phone"
                                    value={homePhone}
                                    isDisabled
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="cell">
                                {apiKey && (
                                    <TextField
                                        id="profile-apikey"
                                        label="APIKey"
                                        value={apiKey}
                                        isDisabled
                                    />
                                )}
                            </div>
                            <div className="cell buttons">
                                {isDeveloper && (
                                    <div className="api-key-button-wrapper">
                                        <Button
                                            className="api-key-button"
                                            appearance="tertiary"
                                            onClick={this.generateApiKey}
                                        >
                                            Generate New APIKey
                                        </Button>
                                    </div>
                                )}
                                {!isDeveloper && (
                                    <div className="api-key-button-wrapper">
                                        <Button
                                            className="api-key-button"
                                            appearance="secondary"
                                            onClick={this.enableDeveloperAccess}
                                        >
                                            Enable Developer Access
                                        </Button>
                                    </div>
                                )}
                            </div>
                            {showAgreementModal && (
                                <div>
                                    <ClickWrapModal
                                        shouldPresent
                                        onCancel={this.onModalCancel}
                                        onContinue={this.onModalContinue}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="row">
                            <div className="cell buttons">
                                <Button onClick={this.goEditProfile}>
                                    Edit Recreation.gov Profile
                                </Button>
                            </div>
                        </div>
                    </div>
                </main>
            </SkipNavContent>
        );
    }
}

const mapStateToProps = (state: any) => ({ currentUser: getCurrentUser(state) });

const mapDispatchToProps = (dispatch: any) =>
    bindActionCreators(
        { generateApiKey: _generateApiKey, updateCurrentUser: _updateCurrentUser },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
