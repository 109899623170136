/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */
import { getHost } from '../../dev/env';

export type Headers = { [key: string]: string };
export type AnyFunction<R = any, P = any> = (...params: P[]) => R;
export type AnyObject<T = any> = { [key: string]: T };
export type TargetedEvent<T = any> = { target: T & { value: any } };
export type UniqueKey = { _key: string };
export type UniqueKeyObject<O extends object = object> = UniqueKey & O;
export type NotVoid<T = any> = T extends void ? never : T;

export const contactUsUrl = `${process.env.SITE_ROOT}/contact-us`;
export const signUpUrl = `${process.env.SITE_ROOT}/sign-up`;
export const forgotUrl = `${process.env.SITE_ROOT}/reset-password`;
export const jsonDownloadURL = `${process.env.RIDB_ROOT}/downloads/RIDBFullExport_V1_JSON.zip`;
export const csvDownloadURL = `${process.env.RIDB_ROOT}/downloads/RIDBFullExport_V1_CSV.zip`;

export const isLocalEnvironment =
    process.env.IS_LOCAL === 'true' || process.env.IS_LOCAL === true;

export const emptyFunction = () => {};

export const getHostUrl = (isAPI = true) => getHost(process.env.ENV, isAPI);

export const routeBase = process.env.ROUTE_BASE || '';
export const configurePath = (path: string) => routeBase + path;

export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
export const MONTH = (days = 30) => DAY * days;
export const YEAR = DAY * 365;
export const DECADE = YEAR * 10;
export const CENTURY = DECADE * 10;

export function getAssetPath(path: string) {
    return `${process.env.CDN}/${path}`;
}

export function mergeDefaults<T extends AnyObject>(
    item = {} as T,
    defaults: AnyObject
): T {
    const finalProps = {} as any;
    Object.keys(item).forEach((key) => {
        finalProps[key] = item[key];
    });
    Object.keys(defaults).forEach((key) => {
        if (!(typeof finalProps[key] === 'boolean') && !finalProps[key]) {
            finalProps[key] = defaults[key];
        }
    });
    return finalProps as T;
}

export function mergeDefaultsPermitEmptyString<T extends AnyObject>(
    item: T = {} as T,
    defaults: AnyObject
): T {
    const finalProps = {} as any;
    Object.keys(item).forEach((key) => {
        finalProps[key] = item[key];
    });
    Object.keys(defaults).forEach((key) => {
        if (
            !(typeof finalProps[key] === 'boolean') &&
            !(finalProps[key] === '') &&
            !finalProps[key]
        ) {
            finalProps[key] = defaults[key];
        }
    });
    return finalProps as T;
}

export function iterateEnum(enumeration: any, callBack: (key: any, value: any) => void) {
    Object.keys(enumeration).forEach((key) => callBack(key, enumeration[key]));
}

export function generateUuid() {
    // @ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        // eslint-disable-next-line no-bitwise
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(
            16
        )
    );
}
