/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

export interface IRIDBRoute {
    exact?: boolean;
    requiresWriteAccess?: any;
    requiresAdminAccess?: any;
    path: string;
    componentName: string;
    title: string;
    requiresAuth?: boolean;
}

export const routes: IRIDBRoute[] = [
    {
        path: '/landing',
        componentName: 'LandingPage',
        title: 'Landing Page',
        exact: true,
    },
    {
        path: '/download',
        componentName: 'DownloadPage',
        title: 'Download Page',
    },
    {
        path: '/access-agreement-ridb',
        componentName: 'AccessAgreementPage',
        title: 'RIDB Access Agreement Page',
    },
    {
        path: '/login',
        componentName: 'LoginPage',
        title: 'Login Page',
    },
    {
        path: '/profile',
        componentName: 'ProfilePage',
        title: 'Profile Page',
    },
    {
        path: '/publish-to-camping',
        componentName: 'PublishToCampingPage',
        requiresAuth: true,
        requiresWriteAccess: true,
        title: 'Publish To Camping Page',
    },
    {
        path: '/machine-tokens',
        componentName: 'MachineTokensPage',
        title: 'Machine Tokens Page',
    },
    {
        path: '/docs',
        componentName: 'DocumentationPage',
        title: 'Documentation Page',
    },
    {
        path: '/changelog',
        componentName: 'ChangeLogPage',
        title: 'Change Log Page',
    },
    {
        path: '/search',
        componentName: 'SearchPage',
        title: 'Search Page',
    },
    {
        path: '/standards',
        componentName: 'StandardsPage',
        title: 'Standards Page',
    },
    {
        path: '/data-steward/:assetType/:assetId/display',
        componentName: 'StewardAssetDisplayPage',
        requiresAuth: true,
        requiresWriteAccess: true,
        title: 'Data Steward Display Page',
    },
    {
        path: '/data-steward',
        componentName: 'StewardAssetEntryPage',
        requiresAuth: true,
        requiresWriteAccess: true,
        title: 'Data Steward Entry Page',
        exact: true,
    },
    {
        path: '/data-steward/:assetType/:assetId/edit',
        componentName: 'StewardAssetEditPage',
        requiresAuth: true,
        requiresWriteAccess: true,
        title: 'Data Steward Edit Page',
    },
    {
        path: '/data-steward/:assetType/new',
        componentName: 'StewardAssetNewPage',
        requiresAuth: true,
        requiresWriteAccess: true,
        title: 'Data Steward New Page',
    },
    {
        path: '/data-steward/:assetType/search',
        componentName: 'StewardAssetSearchPage',
        requiresAuth: true,
        requiresWriteAccess: true,
        title: 'Data Steward Search Page',
    },
    {
        path: '/admin',
        componentName: 'UserAdminPage',
        requiresAuth: true,
        requiresAdminAccess: true,
        title: 'User Admin Page',
    },
];

export function getRoutePropsFromPath(path: string): IRIDBRoute {
    const detailsArr = routes.filter((r) => r.path === path);
    return detailsArr && detailsArr.length === 1 ? detailsArr[0] : undefined;
}
