/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isEqual from 'lodash/isEqual';

import { Pagination } from 'sarsaparilla';
import { withCompatRouter } from 'sarsaparilla/legacy';

import { fetchAssets as _fetchAssets } from '../../actions/asset';
import { fetchOrganizations as _fetchOrganizations } from '../../actions/organization';
import { saveSearch as _saveSearch } from '../../actions/search';
import { getState } from '../../reducers';
import { getCurrentUser } from '../../reducers/currentUser';
import { getAssetCount, getAssets } from '../../reducers/assets';
import { getOrganization, getOrganizations } from '../../reducers/organizations';
import { getSearch } from '../../reducers/search';
import { ICurrentUser } from '../../shared/types/auth/currentUser';
import { ISearchQuery } from '../../shared/types/search';
import { IOrganization } from '../../shared/types/asset/organization';
import { IAsset } from '../../shared/types/asset/asset';
import * as globals from '../../shared/globals';
import { StateStatus } from '../../shared/utils/async';
import { flattenOrg, flattenOrgs, orgsToOptions } from '../../shared/utils/organization';
import { StewardSearchForm } from '../../components/StewardAsset/StewardSearchForm';
import RidbLink from '../../shared/components/RidbLink';
import { ReactTableCell } from '../../shared/types/reactTable';
import { noSearchResults } from '../../shared/constants/search';
import ReactTable from '../../components/ReactTable';
import Auth, { AuthTargetedTypes } from '../../shared/services/auth';
import AnyFunction = globals.AnyFunction;

type StewardAssetSearchPageProps = {
    router: {
        params: Record<string, string>;
        navigate: (whereTo: number | string) => void;
    };
    currentUser?: ICurrentUser;
    searchQuery: ISearchQuery;
    organization?: IOrganization;
    organizations?: IOrganization[];
    assets?: IAsset[];
    states?: { [key: string]: number };
    saveSearch: AnyFunction;
    fetchAssets: AnyFunction;
    fetchOrganizations: AnyFunction;
    assetCount?: number;
};

type StewardAssetSearchPageState = {
    asset: { label: string; code: string };
};

class StewardAssetSearchPage extends Component<
    StewardAssetSearchPageProps,
    StewardAssetSearchPageState
> {
    readonly columns = [
        {
            Header: 'Name',
            id: 'name',
            accessor: 'name',
            Cell: (c: ReactTableCell) => {
                const {
                    original: { name, id },
                } = c.row;
                const { code } = this.state.asset;
                return (
                    <RidbLink to={`/data-steward/${code}/${id}/display`}>
                        {name || '-- missing name --'}
                    </RidbLink>
                );
            },
        },
        {
            Header: 'Organization',
            id: 'organization',
            accessor: 'org_name',
        },
        {
            Header: 'Last Updated At',
            id: 'updated_at',
            accessor: 'updated_at',
        },
        {
            Header: '',
            id: 'export',
            accessor: 'export',
            width: 180,
            Cell: (c: ReactTableCell) => {
                const {
                    original: { id, reservable },
                } = c.row;
                const { code } = this.state.asset;
                return (
                    <div>
                        <RidbLink to={`/data-steward/${code}/${id}/display`}>
                            View
                        </RidbLink>
                        &nbsp;|&nbsp;
                        {reservable ? (
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href={`${process.env['HUB_EDIT_URL']}/${id}`}
                            >
                                Edit in the Hub
                            </a>
                        ) : (
                            <RidbLink to={`/data-steward/${code}/${id}/edit`}>
                                Edit
                            </RidbLink>
                        )}
                    </div>
                );
            },
        },
    ];

    constructor(props: StewardAssetSearchPageProps) {
        super(props);

        const { assetType } = props.router.params;

        const asset =
            assetType === 'recarea'
                ? { label: 'Recreation Area', code: 'recarea' }
                : { label: 'Facility', code: 'facility' };
        this.state = { asset };
    }

    componentDidMount() {
        const {
            states: { organizations },
            fetchOrganizations,
            searchQuery,
            searchQuery: { assetTypes, orgIds },
            currentUser,
        } = this.props;
        const {
            asset: { code },
        } = this.state;
        if (organizations !== StateStatus.READY) {
            fetchOrganizations().catch((e: any) => console.error(e));
        }

        const newSearchParams = { assetTypes: undefined, orgIds: undefined } as any;
        let setSearch = false;

        if (assetTypes.length !== 1 || assetTypes[0] !== code) {
            newSearchParams.assetTypes = [code];
            setSearch = true;
        }

        if (orgIds.length === 0 && currentUser) {
            newSearchParams.orgIds = [currentUser.apiKey.orgId];
            setSearch = true;
        }

        if (setSearch) {
            return this.props.saveSearch({ ...searchQuery, ...newSearchParams });
        }
        this.goSearch(searchQuery);
    }

    componentDidUpdate({ searchQuery: prevSearchQuery }: any) {
        const { searchQuery } = this.props;
        if (!isEqual(searchQuery, prevSearchQuery)) {
            this.goSearch(searchQuery);
        }
    }

    setPage = (page: any) => {
        const { saveSearch, searchQuery } = this.props;
        saveSearch({ ...searchQuery, page })
            .then(this.goSearch)
            .catch((e: any) => console.error(e));
    };

    saveSearch = (query: ISearchQuery) => {
        // if no orgIds provided, must use users orgId
        if (query?.orgIds && query.orgIds.length === 0) {
            const {
                apiKey: { orgId },
            } = Auth.target(AuthTargetedTypes.USER);
            if (orgId) {
                query.orgIds.push(orgId);
            }
        }
        const { saveSearch } = this.props;
        saveSearch(query)
            .then(this.goSearch(query))
            .catch((e: any) => console.error(e));
    };

    goSearch = (searchQuery: ISearchQuery) => {
        const {
            searchQuery: propsSearchQuery,
            states: { assets },
            fetchAssets,
        } = this.props;
        const query = searchQuery || propsSearchQuery;
        if (assets === StateStatus.LOADING) {
            return;
        }
        if (!searchQuery) {
            query.terms = '';
        }
        fetchAssets(query).catch((e: any) => console.error(e));
    };

    goInsert = (query: ISearchQuery) => {
        const assetType = query.assetTypes[0];
        this.props.router.navigate(`/data-steward/${assetType}/new`);
    };

    render() {
        const {
            organization,
            organizations,
            assets,
            assetCount,
            searchQuery: { orgIds, limit, page, terms },
        } = this.props;
        const {
            asset: { label, code },
        } = this.state;

        const pages = Math.ceil(assetCount / limit);

        const orgName = organization
            ? organization.name.toLowerCase() === 'root'
                ? ''
                : organization.name
            : '';
        const orgs = organization ? flattenOrg(organization) : flattenOrgs(organizations);
        const orgOptions = orgsToOptions(orgs);
        return (
            <div className="search-page">
                <h1>
                    {orgName} {label} Search Results{' '}
                    {terms && terms.length > 0 ? `For "${terms}"` : ''}
                </h1>
                <StewardSearchForm
                    id={code}
                    name={label}
                    terms={terms || ''}
                    orgId={orgIds[0] || ''}
                    orgOptions={orgOptions}
                    onSearch={this.saveSearch}
                    onInsert={this.goInsert}
                    searchOnChange
                    showLabel={false}
                    showLimitSelect
                />

                <div className="search-results-table margin-bottom">
                    <ReactTable
                        noDataText={noSearchResults}
                        className="table-no-border"
                        columns={this.columns}
                        data={assets}
                        defaultSortId="name"
                    />
                </div>
                <div className="row">
                    <div className="cell biggest">
                        <p>
                            Showing {Math.min(assetCount, page * limit + 1)} -{' '}
                            {Math.min(assetCount, page * limit + limit)} of {assetCount}{' '}
                            entries
                        </p>
                    </div>
                    <div className="cell align-right">
                        {pages ? (
                            <Pagination
                                onChange={this.setPage}
                                activePage={page || 0}
                                pages={pages}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    currentUser: getCurrentUser(state),
    searchQuery: getSearch(state),
    organization: getOrganization(state, getCurrentUser(state).apiKey.orgId),
    organizations: getOrganizations(state),
    assets: getAssets(state),
    assetCount: getAssetCount(state),
    states: getState(state, 'assets', 'organizations'),
});

const mapDispatchToProps = (dispatch: any) =>
    bindActionCreators(
        {
            saveSearch: _saveSearch,
            fetchAssets: _fetchAssets,
            fetchOrganizations: _fetchOrganizations,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCompatRouter(StewardAssetSearchPage));
