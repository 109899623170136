/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchChangeLogItems as _fetchChangeLogItems } from '../actions/changeLog';
import { getChangeLog } from '../reducers/changeLog';
import { StateStatus } from '../shared/utils/async';
import { getState } from '../reducers';
import { IChangeLogItem } from '../shared/types/changeLog';
import * as globals from '../shared/globals';
import AnyFunction = globals.AnyFunction;
import ChangeLogList from '../components/ChangeLog/ChangeLogList';
import { useMountEffect } from '../shared/hooks/useMountEffect';
import '../scss/containers/_ChangelogPage.scss';

type ChangeLogPageProps = {
    changeLog: IChangeLogItem[];
    states?: any;
    fetchChangeLogItems?: AnyFunction;
};

function ChangeLogPage(props: ChangeLogPageProps) {
    const {
        states: { changeLog: changeLogStatus },
        fetchChangeLogItems,
        changeLog = [],
    } = props;
    const [sortedChangeLog, setSortedChangeLog] = useState([]);

    useMountEffect(() => {
        if (changeLogStatus === StateStatus.UNLOADED) {
            fetchChangeLogItems();
        }
    });

    useEffect(() => {
        if (changeLogStatus === StateStatus.READY) {
            const sChangeLog = changeLog.sort((a, b) => {
                if (!a.dateString) {
                    return -1;
                }
                const aDateTime = new Date(a.dateString).getTime();
                const bDateTime = new Date(b.dateString).getTime();
                return aDateTime > bDateTime ? -1 : aDateTime === bDateTime ? 0 : 1;
            });
            setSortedChangeLog(sChangeLog);
        }
    }, [changeLog]);

    return (
        <main role="main" id="developer-portal-changelog-page" className="changelog-page">
            <div className="body-wrap">
                <div className="rec-nested-wrap">
                    <div className="body-section body-section">
                        <h2>RIDB Changelog</h2>
                        <ChangeLogList changeLog={sortedChangeLog} />
                    </div>
                </div>
            </div>
        </main>
    );
}

const mapStateToProps = (state: any) => ({
    changeLog: getChangeLog(state),
    states: getState(state, 'changeLog'),
});

const mapDispatchToProps = (dispatch: any) =>
    bindActionCreators(
        {
            fetchChangeLogItems: _fetchChangeLogItems,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ChangeLogPage);
