/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Line } from 'rc-progress';
import {
    Notification,
    SpinnerModal,
    Stack,
    Box,
    Heading,
    Text,
    Icon,
    Button,
} from 'sarsaparilla';
import { getSaveStatus } from '../../../reducers/saveStatus';
import * as globals from '../../../shared/globals';
import AnyFunction = globals.AnyFunction;
import '../../../scss/containers/StewardAssetEditPage/_SaveStatus.scss';

type SaveStatusProps = {
    status?: {
        total: number;
        complete: number;
        errors: Array<{ description: string }>;
    };
    onClose?: AnyFunction;
};

export function SaveStatus(props: SaveStatusProps) {
    const SarsaStack = Stack as any;
    const SarsaBox = Box as any;
    const { onClose } = props;
    const status = useSelector((state: any) => getSaveStatus(state));

    const { total, complete, errors } = status;

    const isLoading = total > 0;
    const hasError = errors.length > 0;
    const isSuccessful = total === 0 && errors.length === 0;

    useEffect(() => {
        if (isSuccessful) {
            setTimeout(() => {
                onClose({ shouldRedirect: true });
            }, 5000);
        }
    }, [status, onClose, isSuccessful]);

    return (
        <SpinnerModal isOpen isSpinning={isLoading}>
            <SarsaStack>
                {hasError && (
                    <Notification type="error">
                        <div className="rec-notification-title">
                            Problems while saving:
                        </div>
                        <ul>
                            {errors.map(({ description }, i) => (
                                <li key={`${description}-${i}`}>{description}</li>
                            ))}
                        </ul>
                        <Button
                            appearance="link"
                            onClick={() => onClose({ shouldRedirect: false })}
                        >
                            Close
                        </Button>
                    </Notification>
                )}

                {isSuccessful && (
                    <Notification title="Update Successful" type="success">
                        Page would redirect in 5 seconds...&nbsp;
                        <Button
                            appearance="link"
                            onClick={() => onClose({ shouldRedirect: true })}
                        >
                            Go Back
                        </Button>
                    </Notification>
                )}

                {isLoading && (
                    <>
                        <SarsaBox alignX="center" className="box-red">
                            <Icon iconName="warning" size="lg" />
                        </SarsaBox>

                        <Heading
                            headingLevel={1}
                            appearance="h5"
                            className="center-align"
                        >
                            Syncing Changes
                        </Heading>

                        <Text className="center-align">Please wait</Text>

                        <Line
                            percent={(100.0 * complete) / total}
                            trailWidth={3}
                            strokeWidth={3}
                        />

                        <Text className="center-align" fontWeight="bold">
                            Do not reload or refresh browser
                        </Text>
                    </>
                )}
            </SarsaStack>
        </SpinnerModal>
    );
}

export default SaveStatus;
