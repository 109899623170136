/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */
import React, { Component } from 'react';
import { Label, TextField } from 'sarsaparilla';
import * as globals from '../globals';
import AnyFunction = globals.AnyFunction;
import TargetedEvent = globals.TargetedEvent;
import mergeDefaults = globals.mergeDefaults;
import '../../scss/components/_TextInput.scss';

type TextInputProps = {
    id: string;
    label?: string;
    value: any;
    placeholder?: string;
    className?: string;
    password?: boolean;
    inline?: boolean;
    disabled?: boolean;
    required?: boolean;
    readOnly?: boolean;
    isInvalid?: boolean;
    selectAllOnFocus?: boolean;
    validator?: AnyFunction;
    isValid?: boolean;
    error?: any;
    showError?: boolean | AnyFunction;
    iconLeft?: AnyFunction;
    iconRight?: AnyFunction;
    onChange?: AnyFunction;
    onFocus?: AnyFunction;
    onBlur?: AnyFunction;
    onKeyDown?: AnyFunction;
    onKeyUp?: AnyFunction;
    onKeyPress?: AnyFunction;
};

const defaultProps = {
    value: undefined as any,
    id: undefined as any,
    className: '',
    placeholder: '',
    password: false,
    inline: false,
    disabled: false,
    required: false,
    readOnly: false,
    selectAllOnFocus: false,
    validator: () => false,
    isValid: true,
    error: false,
    showError: ({ blurred }: { blurred: boolean }) => blurred,
};

type TextInputState = Partial<{
    showError: boolean;
    isValid: boolean;
    error: Error;
    value: any;
    focused: boolean;
    changed: boolean;
    blurred: boolean;
    touched: boolean;
}>;

export enum TextInputEventTypes {
    ON_BLUR = 'blurred',
    ON_FOCUS = 'focused',
    ON_CHANGE = 'changed',
}

class IconTextInput extends Component<TextInputProps, TextInputState> {
    constructor(props = defaultProps) {
        super(props);
        const { value, showError: propsShowError } = mergeDefaults<TextInputProps>(
            props,
            defaultProps
        );
        const { error, isValid } = this.validate(value);
        const events = {
            [TextInputEventTypes.ON_CHANGE]: false,
            [TextInputEventTypes.ON_FOCUS]: false,
            [TextInputEventTypes.ON_BLUR]: false,
        };
        const showError = this.showError(propsShowError, events);

        this.state = { ...events, showError, isValid, error, value, touched: false };
    }

    componentDidUpdate(
        prevProps: Readonly<TextInputProps>,
        prevState: Readonly<TextInputState>
    ): void {
        const { isValid: prevIsValid, value: prevValue } = prevProps;
        const { isValid, error, value } = this.props;
        if (prevIsValid !== isValid) {
            this.setState(() => ({
                ...prevState,
                isValid,
                error,
                value,
            }));
        } else if (prevValue !== value) {
            this.setState({ value });
        }
    }

    onEvent = (
        event: TargetedEvent<{ select?: AnyFunction }>,
        type: TextInputEventTypes
    ) => {
        const { onFocus, onChange, onBlur, selectAllOnFocus, showError } = this.props;
        const { value } = event.target;
        const { error, isValid } = this.validate(value);
        const toShowError = this.showError(showError, { [type]: true });
        const fnMap: { [key in TextInputEventTypes]: AnyFunction } = {
            [TextInputEventTypes.ON_BLUR]: onBlur,
            [TextInputEventTypes.ON_CHANGE]: onChange,
            [TextInputEventTypes.ON_FOCUS]: onFocus,
        };
        switch (type) {
            case TextInputEventTypes.ON_BLUR:
            case TextInputEventTypes.ON_CHANGE:
                // specific actions here
                this.setState({ touched: true });
                break;
            case TextInputEventTypes.ON_FOCUS:
                if (selectAllOnFocus) {
                    const { select } = event.target;
                    select();
                }
                break;
            default:
        }
        this.setState(
            (prevState) => ({
                ...prevState,
                [type]: true,
                showError: toShowError,
                isValid,
                error,
                value,
            }),
            () => {
                const func = fnMap[type];
                if (func) {
                    func(value, isValid, error);
                }
            }
        );
    };

    validate = (value: any) => {
        try {
            const { validator } = this.props;
            const error = validator(value);
            return { error, isValid: !error };
        } catch (e) {
            return { error: (e as Error)?.message, isValid: false };
        }
    };

    showError = (showError: any, events = {}) => {
        const { focused, changed, blurred } = { ...this.state, ...events };
        return typeof showError === 'function'
            ? showError({ focused, changed, blurred })
            : showError;
    };

    render() {
        const {
            id,
            label,
            inline,
            password,
            placeholder,
            className,
            required,
            readOnly,
            disabled,
            isInvalid = false,
        } = this.props;
        const { onKeyDown, onKeyUp, onKeyPress } = this.props;
        const { iconLeft: IconLeft, iconRight: IconRight } = this.props;
        const { showError, isValid, error, value } = this.state;
        const inputType = password ? 'password' : 'text';
        const wrapperClass = inline ? 'rec-form-inline-item' : 'rec-form-item-wrap';
        const classes = `${wrapperClass} ${className} ${
            IconLeft ? 'with-left-icon' : ''
        } ${IconRight ? 'with-right-icon' : ''} text-input-width`;

        return (
            <div className={classes}>
                {label && (
                    <Label htmlFor={id}>
                        {label}
                        {required ? <mark title="required">*</mark> : null}
                    </Label>
                )}
                {IconLeft ? <IconLeft /> : null}
                <TextField
                    label=""
                    id={id}
                    isInvalid={this.state.touched && isInvalid}
                    value={value}
                    placeholder={placeholder}
                    enableShowPassword={inputType === 'password'}
                    readOnly={readOnly}
                    disabled={disabled}
                    className={showError && !isValid ? 'rec-error' : ''}
                    type={inputType}
                    onFocus={(e) => this.onEvent(e, TextInputEventTypes.ON_FOCUS)}
                    onChange={(e) => this.onEvent(e, TextInputEventTypes.ON_CHANGE)}
                    onBlur={(e) => this.onEvent(e, TextInputEventTypes.ON_BLUR)}
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                    onKeyPress={onKeyPress}
                />
                {IconRight ? <IconRight /> : null}
                {/* @ts-ignore */}
                {showError && !isValid && <div>{error}</div>}
            </div>
        );
    }
}

export default IconTextInput;
