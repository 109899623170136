/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as globals from '../globals';
import emptyFunction = globals.emptyFunction;

export const alertDefaults = {
    id: '',
    group: '',
    level: 'error',
    title: '',
    message: '',
    onClose: emptyFunction,
    timeout: null,
};
