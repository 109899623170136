/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */
/* eslint-disable react/hook-use-state */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { get, isEqual } from 'lodash';
import memoize from 'memoize-one';
import {
    Tabs,
    Button,
    ButtonGroup,
    Notification,
    TabPanel,
    ContentBlock,
} from 'sarsaparilla';
import SaveStatus from './SaveStatus';
import { getAsset } from '../../../reducers/asset';
import { getOrganization } from '../../../reducers/organizations';
import { getCurrentUser } from '../../../reducers/currentUser';
import { getState } from '../../../reducers';
import { getChangeCount, getErrorCount } from '../../../reducers/changes';
import { StateStatus } from '../../../shared/utils/async';
import { fetchOrganizations } from '../../../actions/organization';
import { fetchAsset, resetAsset } from '../../../actions/asset';
import { addChange, getRef, removeRefs, saveChanges } from '../../../actions/changes';
import { saveClearErrors } from '../../../actions/saveStatus';
import { flattenOrg, orgsToOptions } from '../../../shared/utils/organization';
import {
    determineAssetInvalidItems,
    getAssetTypeDetails,
} from '../../../shared/utils/asset';
import AssetTab from '../../../components/StewardAsset/StewardAssetEdit/tabs/AssetTab';
import ActivitiesTab from '../../../components/StewardAsset/StewardAssetEdit/tabs/ActivitiesTab';
import AddressesTab from '../../../components/StewardAsset/StewardAssetEdit/tabs/AddressesTab';
import LinkTab from '../../../components/StewardAsset/StewardAssetEdit/tabs/LinkTab';
import ImagesTab from '../../../components/StewardAsset/StewardAssetEdit/tabs/ImagesTab';
import EventsTab from '../../../components/StewardAsset/StewardAssetEdit/tabs/EventsTab';
import * as globals from '../../../shared/globals';
import AnyObject = globals.AnyObject;
import {
    StewardAssetInvalidItem,
    StewardAssetTabType,
} from '../../../shared/types/asset/asset';
import '../../../scss/containers/StewardAssetEditPage/_StewardAssetEditPage.scss';
import AnyFunction = globals.AnyFunction;
import mergeDefaultsPermitEmptyString = globals.mergeDefaultsPermitEmptyString;
import { getNewChanges } from '../../../shared/constants/asset/asset';

function StewardAssetEditPage() {
    const [isProgressOpen, toggleProgressModal] = useState(false);
    const [assetLastRef, updateAssetLastRef] = useState('');
    const dispatch = useDispatch() as any;
    const navigate = useNavigate();
    const { assetId, assetType } = useParams();

    const asset = useSelector((state) => getAsset(state));
    const [ref, updateRef] = useState(undefined);
    const [assetInState, updateAssetInState]: [any, AnyFunction] = useState({});
    const [dispatchedChanges, updateDispatchedChanges] = useState([]);
    const organization = useSelector((state) =>
        getOrganization(state, getCurrentUser(state).apiKey.orgId)
    );
    const states: AnyObject = useSelector((state) =>
        getState(state, 'organizations', 'currentUser', 'asset')
    );
    const changes = useSelector((state: any) => state.portal.changes);
    const changeCount = useSelector((state: any) => getChangeCount(state));
    const errorCount = useSelector((state: any) => getErrorCount(state));
    const [allInvalidFields, updateAllInvalidFields] = useState([]);

    const details = getAssetTypeDetails(assetType);
    const { organizations } = states;

    useEffect(() => {
        if (organizations === StateStatus.UNLOADED) {
            dispatch(fetchOrganizations());
        }

        if (assetId) {
            const doesAssetNeedUpdate = assetId && get(asset, 'id', '') !== assetId;
            if (doesAssetNeedUpdate) {
                dispatch(fetchAsset(assetType, assetId, { full: true }));
            }
            if (!ref) {
                const newRef = dispatch(getRef(details.plural, { id: assetId }, -1, []));
                updateRef(newRef);
            }
        }

        return () => {
            dispatch(removeRefs());
            dispatch(saveClearErrors());
        };
    }, []);

    useEffect(() => {
        if (Object.keys(asset).length !== 0 && stateAsset === StateStatus.READY) {
            updateAssetInState(asset);
        }
    }, [asset]);

    useEffect(() => {
        const newChanges = getNewChanges(asset, assetInState);
        newChanges.forEach((c) => {
            if (dispatchedChanges.filter((change) => isEqual(change, c)).length < 1) {
                dispatch(addChange(ref, c));
                updateDispatchedChanges([...dispatchedChanges, c]);
            }
        });
    }, [assetInState]);

    const getOrgOptions = memoize((organizationsState, currentUserState) =>
        organizationsState === StateStatus.READY &&
        currentUserState === StateStatus.READY &&
        organization
            ? orgsToOptions(flattenOrg(organization))
            : []
    );

    const handleCancel = () => {
        navigate(`/data-steward/${assetType}/search`);
    };

    const handleAssetUpdate = (assetChange: any, tabType: StewardAssetTabType) => {
        switch (tabType) {
            case 'assets':
                const finalAsset = mergeDefaultsPermitEmptyString(
                    assetChange,
                    assetInState
                );
                updateAssetInState(finalAsset);
                break;
            case 'activities':
            case 'addresses':
            case 'media':
            case 'links':
            case 'events':
                const newState = { ...assetInState };
                delete newState[tabType];
                newState[tabType] = assetChange;
                updateAssetInState({ ...newState });
                break;
            default:
        }
    };

    const handleSave = () => {
        dispatch(saveClearErrors());

        const { longitude: assetLongitude, latitude: assetLatitude } = asset;
        toggleProgressModal(true);
        // special case where if only 1 is provided, need to send back other for BE
        const changesToSend = changes
            .map((change) => {
                if (!change || !change.changes) {
                    return change;
                }
                const copy = { ...change };
                const { latitude: changesLatitude, longitude: changesLongitude } =
                    copy.changes;
                if (changesLatitude && !changesLongitude) {
                    copy.changes.longitude = assetLongitude.toString();
                }
                if (changesLongitude && !changesLatitude) {
                    copy.changes.latitude = assetLatitude.toString();
                }
                return copy;
            })
            .concat(allInvalidFields.filter((f) => f.invalid));
        const promise: any = dispatch(saveChanges(changesToSend, assetId, true));
        // @ts-expect-error
        promise.then(() => dispatch(resetAsset())).catch((e) => console.error(e));
    };

    const handleInvalidItems = (invalidItems: StewardAssetInvalidItem[]) => {
        const finalItems = determineAssetInvalidItems(
            [...allInvalidFields],
            invalidItems
        );
        updateAllInvalidFields(finalItems.filter((i) => !i._delete));
    };

    const handleCloseModal = ({ shouldRedirect }: any) => {
        if (shouldRedirect) {
            navigate(`/data-steward/${assetType}/${assetId}/display`);
        } else {
            toggleProgressModal(false);
        }
        dispatch(saveClearErrors());
    };

    const handleLastAssetRef = (key: number) => {
        const formattedRef = `ref-${key}`;
        if (assetLastRef !== formattedRef) {
            updateAssetLastRef(formattedRef);
        }
    };

    const {
        organizations: organizationsState,
        currentUser: currentUserState,
        asset: stateAsset,
    } = states;

    const orgOptions = getOrgOptions(organizationsState, currentUserState);
    const loading = stateAsset !== StateStatus.READY || assetId !== asset.id;
    const reservable = get(asset, 'reservable', false);
    const { name, activities, addresses, links, media, event } = details;
    const savedActivityIds = (asset.activities || []).map((a: any) => a.activityId);
    return (
        <ContentBlock className="steward-asset-edit">
            {reservable && (
                <Notification title="Warning:" type="warning">
                    This {name} is not editable.
                </Notification>
            )}

            {isProgressOpen && <SaveStatus onClose={handleCloseModal} />}

            <Tabs appearance="block" stretchTabsToFit={false} className="mt-3">
                <TabPanel label={name} className="asset-tab">
                    {ref && (
                        <AssetTab
                            lastRef={assetLastRef}
                            onLastAssetRef={handleLastAssetRef}
                            assetDetails={details}
                            value={assetInState}
                            orgOptions={orgOptions}
                            onAssetUpdate={handleAssetUpdate}
                            onInvalidItemsChange={handleInvalidItems}
                            isLoading={loading}
                        />
                    )}
                </TabPanel>

                <TabPanel label="Activities" className="activities-tab">
                    <ActivitiesTab
                        savedActivityIds={savedActivityIds}
                        activityType={activities}
                        onAssetUpdate={handleAssetUpdate}
                        value={assetInState}
                        isLoading={loading}
                    />
                </TabPanel>

                <TabPanel label="Addresses" className="addresses-tab">
                    <AddressesTab
                        addressType={addresses}
                        onAssetUpdate={handleAssetUpdate}
                        value={assetInState}
                        isLoading={loading}
                    />
                </TabPanel>

                <TabPanel label="Links" className="links-tab">
                    <LinkTab
                        linkType={links}
                        onAssetUpdate={handleAssetUpdate}
                        value={assetInState}
                        onInvalidItemsChange={handleInvalidItems}
                        isLoading={loading}
                    />
                </TabPanel>

                <TabPanel label="Images" className="images-tab">
                    <ImagesTab
                        mediaType={media}
                        onAssetUpdate={handleAssetUpdate}
                        onInvalidItemsChange={handleInvalidItems}
                        value={assetInState}
                        isLoading={loading}
                    />
                </TabPanel>

                <TabPanel label="Events" className="events-tab">
                    <EventsTab
                        eventType={event}
                        assetType={assetType}
                        onAssetUpdate={handleAssetUpdate}
                        onInvalidItemsChange={handleInvalidItems}
                        eventsToLoad={assetInState ? assetInState.eventsToLoad : false}
                        asset={assetInState}
                        isLoading={loading}
                    />
                </TabPanel>
            </Tabs>

            <div className="form-footer">
                <div className="footer-status">
                    STATUS: {changeCount} Changes, {errorCount} Errors
                </div>

                <ButtonGroup>
                    <Button appearance="tertiary" onClick={handleCancel}>
                        Cancel
                    </Button>
                    {!reservable && (
                        <Button onClick={handleSave} isLoading={loading}>
                            Save Changes
                        </Button>
                    )}
                </ButtonGroup>
            </div>
        </ContentBlock>
    );
}

export default StewardAssetEditPage;
