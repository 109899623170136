/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import {
    Button,
    Heading,
    HelmetWrapper,
    Icon,
    Inline,
    Notification,
    SkipNavContent,
    VerticalDivider,
} from 'sarsaparilla';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCurrentUser } from '../reducers/currentUser';
import * as globals from '../shared/globals';
import jsonDownloadURL = globals.jsonDownloadURL;
import csvDownloadURL = globals.csvDownloadURL;
import { getHost } from '../../dev/env';
import '../scss/containers/_DownloadPage.scss';
import RidbLink from '../shared/components/RidbLink';

function DownloadPage() {
    const rootUrl = getHost(process.env['ENV'], true);
    const startYear = 2006;
    const endYear = 2024;
    const years = new Array(endYear - startYear)
        .fill(0)
        .map((_, index) => startYear + index);
    const recDataDownloads = {
        [csvDownloadURL]: 'CSV Format',
        [jsonDownloadURL]: 'JSON Format',
    };

    return (
        <SkipNavContent>
            <HelmetWrapper title="RIDB Recreation Data" />
            <main
                role="main"
                id="developer-portal-download-page"
                className="download-page"
            >
                <div className="body-wrap">
                    <div>
                        <div className="body-section body-section">
                            <Heading headingLevel={1} className="recreation-data-heading">
                                RIDB Recreation Data
                            </Heading>
                            <p>
                                To download all the RIDB recreation area, facility, and
                                site level data in CSV or JSON format, please select the
                                link below. <em>Updated Daily.</em>
                            </p>
                            <Inline space="sm">
                                {Object.keys(recDataDownloads).map(
                                    (link: string, index: number) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <Button
                                                    appearance="link"
                                                    href={link}
                                                    screenReaderTextAfter={`RIDB Recreation Data ${recDataDownloads[link]}`}
                                                    iconAfterElement={
                                                        <Icon
                                                            iconName="file-download"
                                                            size="sm"
                                                        />
                                                    }
                                                >
                                                    {recDataDownloads[link]}
                                                </Button>
                                                {index ===
                                                Object.keys(recDataDownloads).length -
                                                    1 ? null : (
                                                    <VerticalDivider />
                                                )}
                                            </React.Fragment>
                                        );
                                    }
                                )}
                            </Inline>
                        </div>
                        <div className="body-section body-section">
                            <Heading headingLevel={1} className="recreation-data-heading">
                                RIDB/{process.env['SITE_NAME']} Historical Reservation
                                Data
                            </Heading>
                            <p>
                                Historical Reservation data is available for download from
                                fiscal year {startYear} to {endYear}. As of October 12th,
                                2018 future Historical Reservation data will be available
                                in a new format. Please select the year to download a
                                compressed CSV file.
                            </p>
                            <p>
                                <em>Updated May 2024.</em>
                            </p>
                            <Inline space="sm">
                                {years.map((year: number, index: number) => {
                                    return (
                                        <React.Fragment key={year}>
                                            <Button
                                                appearance="link"
                                                href={`${rootUrl}/downloads/reservations${year}.zip`}
                                                screenReaderTextAfter={`RIDB/${process.env['SITE_NAME']} Historical Reservation Data Fiscal Year ${year}`}
                                                iconAfterElement={
                                                    <Icon
                                                        iconName="file-download"
                                                        size="sm"
                                                    />
                                                }
                                            >
                                                {`FY ${year}`}
                                            </Button>

                                            {index === years.length - 1 ? null : (
                                                <VerticalDivider />
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </Inline>
                        </div>
                        <div className="body-section notice-section">
                            <Notification type="info" title="Notice:" headingLevel={3}>
                                <ul>
                                    <li>Data sets may be large</li>
                                    <li>
                                        For more information on how to use the RIDB data,
                                        please visit our&nbsp;
                                        <RidbLink to="/docs">API Documentation</RidbLink>
                                    </li>
                                    <li>
                                        <strong>
                                            By downloading this data, you are agreeing to
                                            the&nbsp;
                                            <RidbLink to="/access-agreement-ridb">
                                                RIDB API Access Agreement.
                                            </RidbLink>
                                        </strong>
                                    </li>
                                </ul>
                            </Notification>
                        </div>
                    </div>
                </div>
            </main>
        </SkipNavContent>
    );
}

const mapStateToProps = (state) => ({
    currentUser: getCurrentUser(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DownloadPage);
