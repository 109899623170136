/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { uniqueId } from 'lodash';
import { Button, Spinner, Box } from 'sarsaparilla';
import { IAsset } from '../../../../shared/types/asset/asset';
import { IEvent } from '../../../../shared/types/asset/event';
import * as globals from '../../../../shared/globals';
import AnyFunction = globals.AnyFunction;
import { eventDefaults } from '../../../../shared/constants/asset/event';
import LoadingWrapper from '../../../../shared/components/LoadingWrapper';
import EventEdit from '../../../../containers/StewardAsset/StewardAssetEditPage/assetEdit/EventEdit';
import { getState } from '../../../../reducers';
import { StateStatus } from '../../../../shared/utils/async';
import { getEvents } from '../../../../reducers/events';
import { fetchEvents, resetEvents as _resetEvents } from '../../../../actions/events';
import '../../../../scss/containers/StewardAssetEditPage/_StewardAssetEditPage.scss';

type EventsTabProps = {
    eventsToLoad?: boolean;
    asset?: IAsset;
    value?: IEvent[];
    eventType?: string;
    states?: { [key: string]: number };
    fetchData: AnyFunction;
    resetEvents: AnyFunction;
    isLoading: boolean;
    onInvalidItemsChange?: AnyFunction;
    onAssetUpdate?: AnyFunction;
};

type EventsTabState = {
    events: IEvent[];
    didInitialLoad: boolean;
};

class EventsTab extends PureComponent<EventsTabProps, EventsTabState> {
    state = {
        events: [] as any,
        didInitialLoad: false,
    };

    componentDidMount() {
        const { eventsToLoad, fetchData } = this.props;
        if (eventsToLoad) {
            fetchData();
        }
    }

    componentDidUpdate(): void {
        const { eventsToLoad, value } = this.props;
        const { events: stateEvents, didInitialLoad } = this.state;
        if (
            eventsToLoad &&
            stateEvents.length === 0 &&
            value &&
            value.length > 0 &&
            !didInitialLoad
        ) {
            this.setState({
                events: value,
                didInitialLoad: true,
            });
        } else {
            this.setState({ events: stateEvents });
        }
    }

    componentWillUnmount() {
        const { resetEvents } = this.props;
        resetEvents();
    }

    addNewEvent = () => {
        const event = { ...eventDefaults, _key: uniqueId('event-') };
        const { onAssetUpdate } = this.props;
        this.setState((prevState) => {
            const events = [...prevState.events, event];
            onAssetUpdate(events, 'events');
            return { ...prevState, events };
        });
    };

    removeEvent = (key: string) => {
        const { onAssetUpdate } = this.props;
        // @ts-expect-error
        const newEvents = this.state.events.filter(({ _key }) => _key !== key);
        onAssetUpdate(newEvents, 'events');
        this.setState({ events: newEvents });
    };

    handleChange = (event: IEvent) => {
        const {
            asset: { events = [] },
            onAssetUpdate,
        } = this.props;
        const newEvents = [...events]
            .filter((e) => e._key !== event._key)
            .concat([event]);
        this.setState({ events: newEvents });
        onAssetUpdate(newEvents, 'events');
    };

    render() {
        const {
            eventsToLoad,
            states: { events },
            eventType,
            isLoading,
            onInvalidItemsChange,
        } = this.props;
        const loading = eventsToLoad && events !== StateStatus.READY;
        const { events: stateEvents } = this.state;

        if (isLoading) {
            return <Spinner isCentered={false} size="xl" />;
        }
        const eventsToShow = [...stateEvents];

        return (
            <LoadingWrapper loading={loading} size="lg">
                <div className="tab-body">
                    {eventsToShow.map((event) => (
                        <Box
                            key={event._key}
                            className="mb-2"
                            padding={['lg']}
                            background="white"
                            border="gray"
                        >
                            <EventEdit
                                onInvalidItemsChange={onInvalidItemsChange}
                                onChange={this.handleChange}
                                key={event._key}
                                value={event}
                                eventType={eventType}
                                onRemove={this.removeEvent}
                            />
                        </Box>
                    ))}
                    <Button className="add-button" onClick={this.addNewEvent}>
                        Add new event
                    </Button>
                </div>
            </LoadingWrapper>
        );
    }
}
// @ts-expect-error
const mapStateToProps = (state) => ({
    value: getEvents(state),
    states: getState(state, 'events'),
});
// @ts-expect-error
const mapDispatchToProps = (dispatch, { assetType, asset: { id } }) => ({
    fetchData: () => dispatch(fetchEvents(assetType, id)),
    resetEvents: () => dispatch(_resetEvents()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventsTab);
