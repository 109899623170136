/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useEffect } from 'react';
import { Spinner, Box, FlexRow, FlexCol } from 'sarsaparilla';
import { useSelector, useDispatch } from 'react-redux';
import { fetchActivities } from '../../../../actions/activities';
import { getActivities } from '../../../../reducers/activities';
import { IActivity } from '../../../../shared/types/asset/activities';
import ActivityOption from '../../../../containers/StewardAsset/StewardAssetEditPage/ActivityOption';
import * as globals from '../../../../shared/globals';
import AnyFunction = globals.AnyFunction;

type ActivitiesTabProps = {
    value: Partial<{
        id: string;
        activities: IActivity[];
    }>;
    activityType: string;
    assetDetails: {
        name: string;
        singular: string;
    };
    isNew: boolean;
    isLoading: boolean;
    onAssetUpdate?: AnyFunction;
    savedActivityIds?: number[];
};

function ActivitiesTab(props: Partial<ActivitiesTabProps>) {
    const {
        value,
        activityType,
        isLoading,
        onAssetUpdate,
        isNew = false,
        savedActivityIds,
    } = props;
    const { activities } = value;

    const dispatch = useDispatch();
    const stateActivities = useSelector((state) => getActivities(state));

    const handleActivitySelected = (activity: {
        activityId: number;
        selected: boolean;
    }) => {
        const { activityId, selected } = activity;
        const newActivities = selected
            ? activities.concat([{ activityId } as IActivity])
            : activities.filter((act) => act.activityId !== activityId);
        onAssetUpdate(newActivities, 'activities');
    };

    useEffect(() => {
        // @ts-expect-error
        dispatch(fetchActivities());
    }, []);

    if (isLoading) {
        return <Spinner isCentered={false} size="xl" />;
    }

    return (
        <Box padding={['lg']} background="white" border="gray">
            <FlexRow>
                {stateActivities.map((activity: any) => (
                    <FlexCol sm={6} lg={4} key={`activity-${activity.id}`}>
                        <ActivityOption
                            savedActivityIds={savedActivityIds}
                            isNew={isNew}
                            onChange={onAssetUpdate ? handleActivitySelected : undefined}
                            activityType={activityType}
                            activity={activity}
                            value={activities.find(
                                (record) => record.activityId === activity.id
                            )}
                        />
                    </FlexCol>
                ))}
            </FlexRow>
        </Box>
    );
}
export default ActivitiesTab;
