/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    HelmetWrapper,
    SkipNavContent,
    Tabs,
    TabPanel,
    ContentBlock,
} from 'sarsaparilla';
import Documentation from '../components/Documentation';
import { getCurrentUser } from '../reducers/currentUser';
import { ICurrentUser } from '../shared/types/auth/currentUser';
import * as globals from '../shared/globals';
import getAssetPath = globals.getAssetPath;

// specs is path relative to the dist
const specsTabs = [
    {
        key: 'ridb',
        label: 'RIDB API',
        specs: `${getAssetPath('swagger/ridb.yaml')}`,
        display: () => true,
    },
    {
        key: 'data-steward',
        label: 'Data Steward API',
        specs: `${getAssetPath('swagger/dataSteward.yaml')}`,
        display: (currentUser: ICurrentUser) =>
            currentUser.apiKey.writeAccess || currentUser.apiKey.adminAccess,
    },
    {
        key: 'availability',
        label: 'Availability API',
        specs: `${getAssetPath('swagger/availability.yaml')}`,
        display: (currentUser: ICurrentUser) =>
            currentUser.apiKey.tier1Access || currentUser.apiKey.adminAccess,
    },
    {
        key: 'data-standard',
        label: 'Data Standard API',
        specs: `${getAssetPath('swagger/dataStandard.yaml')}`,
        display: (currentUser: ICurrentUser) =>
            currentUser.apiKey.writeAccess || currentUser.apiKey.adminAccess,
    },
];

type DocumentationPageProps = {
    currentUser: ICurrentUser;
    route: { swaggerSpecs: string };
};

export function DocumentationPage(props: Partial<DocumentationPageProps> = {}) {
    const { currentUser } = props;

    return (
        <SkipNavContent>
            <HelmetWrapper title="RIDB API Documentation" />
            <ContentBlock>
                <main>
                    <Tabs stretchTabsToFit={true}>
                        {specsTabs
                            .filter(({ display }) => display(currentUser))
                            .map(({ label, key, specs }) => (
                                <TabPanel label={label} key={key}>
                                    <Documentation
                                        specs={specs}
                                        currentUser={currentUser}
                                    />
                                </TabPanel>
                            ))}
                    </Tabs>
                    <div id="swagger-docs" />
                </main>
            </ContentBlock>
        </SkipNavContent>
    );
}

const mapStateToProps = (state: any) => ({
    currentUser: getCurrentUser(state),
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocumentationPage);
