/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { Label } from 'sarsaparilla';
import * as globals from '../globals';
import AnyFunction = globals.AnyFunction;
import mergeDefaults = globals.mergeDefaults;
import '../../scss/components/_InlineCheckboxInput.scss';

type InlineCheckboxInputProps = {
    id: string;
    label: string;
    placeholder?: string;
    value: any;
    className?: string;
    inline?: boolean;
    defaultChecked?: boolean;
    disabled?: boolean;
    onChange?: AnyFunction;
};

const defaultProps = {
    placeholder: '',
    inline: true,
    className: '',
    id: undefined as any,
    value: undefined as any,
    label: undefined as any,
};

function InlineCheckboxInput(props: InlineCheckboxInputProps = defaultProps) {
    const { id, label, inline, placeholder, value, className, ...rest } = mergeDefaults(
        props,
        defaultProps
    );
    const wrapClass = inline ? 'rec-form-inline-item' : 'rec-form-check-wrap';
    const classes = `${wrapClass} ${className}`;
    const labelClasses = `rec-label-checkbox ${rest.disabled && 'disabled'}`;

    return (
        <div className={classes}>
            <Label className={labelClasses} htmlFor={id}>
                <input
                    type="checkbox"
                    id={id}
                    className="rec-input-hide"
                    placeholder={placeholder || label}
                    value={value}
                    {...rest}
                />
                <span className="rec-input-checkbox" />
                {label}
            </Label>
        </div>
    );
}

export default InlineCheckboxInput;
