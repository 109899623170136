/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { PureComponent } from 'react';
import { uniqueId } from 'lodash';
import { Button, Notification, Spinner, Box } from 'sarsaparilla';
import { IImage } from '../../../../shared/types/asset/media';
import { imageDefaults } from '../../../../shared/constants/asset/media';
import ImageEdit from '../../../../containers/StewardAsset/StewardAssetEditPage/assetEdit/ImageEdit';
import '../../../../scss/containers/StewardAssetEditPage/_StewardAssetEditPage.scss';
import * as globals from '../../../../shared/globals';
import AnyFunction = globals.AnyFunction;

type ImagesTabProps = {
    mediaType: string;
    value: {
        media: IImage;
    };
    error: string;
    isLoading: boolean;
    onAssetUpdate?: AnyFunction;
    onInvalidItemsChange?: AnyFunction;
};

type ImagesTabState = {
    media: any;
};

class ImagesTab extends PureComponent<Partial<ImagesTabProps>, ImagesTabState> {
    constructor(props) {
        super(props);
        const {
            value: { media },
        } = this.props;
        this.state = { media };
    }

    addBlankMedia = () => {
        const media = { ...imageDefaults, _key: uniqueId('media-') };
        const { onAssetUpdate } = this.props;

        this.setState((prevState) => {
            const newMedia = [...prevState.media, media];
            if (onAssetUpdate) {
                onAssetUpdate(newMedia, 'media');
            }
            return { ...prevState, media: newMedia };
        });
    };

    removeMedia = (key) => {
        const { onAssetUpdate } = this.props;
        this.setState((prevState) => {
            const media = prevState.media.filter(({ _key }) => _key !== key);
            if (onAssetUpdate) {
                onAssetUpdate(media, 'media');
            }
            return {
                ...prevState,
                media,
            };
        });
    };

    handleChange = (media) => {
        const { onAssetUpdate } = this.props;
        const newMedia = [...this.state.media].filter((med) => med._key !== media._key);
        newMedia.push(media);
        this.setState({ media: newMedia });
        if (onAssetUpdate) {
            onAssetUpdate(newMedia, 'media');
        }
    };

    render() {
        const { mediaType, error, isLoading, onInvalidItemsChange } = this.props;
        const { media: images = [] } = this.state;

        if (isLoading) {
            return <Spinner isCentered={false} size="xl" />;
        }

        return (
            <div className="tab-body">
                {!!error && <Notification type="error">{error}</Notification>}

                {images &&
                    images.length > 0 &&
                    images.map((image) => (
                        <Box
                            key={image._key}
                            className="mb-2"
                            padding={['lg']}
                            background="white"
                            border="gray"
                        >
                            <ImageEdit
                                onInvalidItemsChange={onInvalidItemsChange}
                                key={image._key}
                                value={image}
                                mediaType={mediaType}
                                onRemove={this.removeMedia}
                                onChange={this.handleChange}
                            />
                        </Box>
                    ))}
                <Button className="add-button" onClick={this.addBlankMedia}>
                    Add new image
                </Button>
            </div>
        );
    }
}

export default ImagesTab;
