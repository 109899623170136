/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */
import { uniqueId } from 'lodash';
import { IBaseKeyConfig, Translator } from './translator';
import * as globals from '../../globals';
import { decodeOrganizationKeys } from '../../constants/asset/organization';
import AnyObject = globals.AnyObject;

const keyConfig: IBaseKeyConfig = {
    main: {
        decode: decodeOrganizationKeys,
        encode: undefined,
    },
};

class Organization extends Translator {
    decode = (record: AnyObject) => {
        const { decode: decodeKeys } = this.keys.main;
        return this.translate(decodeKeys, record, { _key: uniqueId('org-') });
    };

    encode = (record: AnyObject) => {
        return undefined;
    };
}

export default new Organization(keyConfig);
