/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState } from 'react';
import { uniqueId, map } from 'lodash';
import { Button, ContentBlock, Notification, Spinner } from 'sarsaparilla';
import { ILink } from '../../../../shared/types/asset/links';
import { linkDefaults } from '../../../../shared/constants/asset/links';
import LinkEdit from '../../../../containers/StewardAsset/StewardAssetEditPage/assetEdit/LinkEdit';
import * as globals from '../../../../shared/globals';
import AnyFunction = globals.AnyFunction;
import '../../../../scss/components/StewardAssetEditPage/tabs/_LinkTab.scss';

type LinkTabProps = {
    linkType: string;
    value: {
        links: ILink[];
    };
    isLoading: boolean;
    onAssetUpdate?: AnyFunction;
    onInvalidItemsChange?: AnyFunction;
};

function LinkTab(props: LinkTabProps) {
    const { linkType, value, isLoading, onAssetUpdate, onInvalidItemsChange } = props;
    const [links, updateLinks] = useState(value.links);

    const addBlankLink = () => {
        const newLinks = [...links, { ...linkDefaults, _key: uniqueId('link-') }];
        if (onAssetUpdate) {
            onAssetUpdate(newLinks, 'links');
        }
        updateLinks(newLinks);
    };

    const removeLink = (key) => {
        const newLinks = links.map((link) => {
            if (link._key !== key) {
                return link;
            }
            return { ...link, _delete: true };
        });
        if (onAssetUpdate) {
            onAssetUpdate(newLinks, 'links');
        }
        updateLinks(newLinks);
    };

    const handleChange = (record: ILink) => {
        const newLinks = [...links].filter((link) => link._key !== record._key);
        newLinks.push(record);
        updateLinks(newLinks);
        if (onAssetUpdate) {
            onAssetUpdate(newLinks, 'links');
        }
    };

    if (isLoading) {
        return <Spinner isCentered={false} size="xl" />;
    }

    const finalLinks = links || [];
    return (
        <ContentBlock>
            <Notification type="info">
                Please allow up to 1 day for any updates to take place on{' '}
                {process.env['SITE_NAME']}.
            </Notification>

            {map(
                finalLinks.filter((a) => !a._delete),
                (link) =>
                    link &&
                    link._key && (
                        <LinkEdit
                            key={link._key}
                            value={link}
                            linkType={linkType}
                            onRemove={removeLink}
                            onInvalidItemsChange={onInvalidItemsChange}
                            onChange={handleChange}
                        />
                    )
            )}

            <Button onClick={addBlankLink}>Add new link</Button>
        </ContentBlock>
    );
}

export default LinkTab;
